import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout/layout'
import SEO from 'components/shared/SEO'
import Cookiebar from 'components/layout/Cookies/Cookiebar'
import Navigation from 'components/layout/Navigation'

import Header from 'components/layout/Header'
import About from 'components/layout/About'
import Location from 'components/layout/Location'
import Flats from 'components/layout/Flats'
import Contact from 'components/layout/Contact'
import Gallery from 'components/layout/Gallery'
import AboutInvestor from 'components/layout/AboutInvestor'
import Footer from 'components/layout/Footer'
import ModalVideo from 'components/layout/ModalVideo/ModalVideo'

import videoLink from 'assets/video/video.mp4'

import type { GallerySingle } from 'components/layout/Gallery'

const IndexPage: React.FC<PageProps<Queries.IndexPageQuery>> = ({ data }) => {
  const HOMEPAGE = data?.wpPage?.homepage
  const PAGE_SEO = data?.wpPage?.seo

  if (!HOMEPAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const [modalVisible, setModalVisible] = React.useState(false)
  const closeModal = () => setModalVisible(false)

  React.useEffect(() => {
    setModalVisible(true)
  }, [])

  const ABOUT_US_BENEFITS = [
    { color: 'orange', text: HOMEPAGE?.aboutUsAdv?.aboutUsAdvOrange! },
    { color: 'yellow', text: HOMEPAGE?.aboutUsAdv?.aboutUsAdvYellow! },
    { color: 'dark', text: HOMEPAGE?.aboutUsAdv?.aboutUsAdvBlue! },
    { color: 'orange', text: HOMEPAGE?.aboutUsAdv?.aboutUsAdvOrange2! },
  ]

  const LOCATION_GALLERY = [
    {
      src: HOMEPAGE?.locationImg?.locationImg1?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: HOMEPAGE?.locationImg?.locationImg1?.altText!,
    },
    {
      src: HOMEPAGE?.locationImg?.locationImg2?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: HOMEPAGE?.locationImg?.locationImg2?.altText!,
    },
    {
      src: HOMEPAGE?.locationImg?.locationImg3?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: HOMEPAGE?.locationImg?.locationImg3?.altText!,
    },
    {
      src: HOMEPAGE?.locationImg?.locationImg4?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: HOMEPAGE?.locationImg?.locationImg4?.altText!,
    },
  ]

  const OPENING_HOURS = [
    {
      day: 'Pn',
      from: HOMEPAGE?.contactHours?.contactHoursPn?.slice(0, 5)!,
      to: HOMEPAGE?.contactHours?.contactHoursPn?.slice(-5)!,
    },
    {
      day: 'Wt',
      from: HOMEPAGE?.contactHours?.contactHoursWt?.slice(0, 5)!,
      to: HOMEPAGE?.contactHours?.contactHoursWt?.slice(-5)!,
    },
    {
      day: 'Śr',
      from: HOMEPAGE?.contactHours?.contactHoursSr?.slice(0, 5)!,
      to: HOMEPAGE?.contactHours?.contactHoursSr?.slice(-5)!,
    },
    {
      day: 'Czw',
      from: HOMEPAGE?.contactHours?.contactHoursCzw?.slice(0, 5)!,
      to: HOMEPAGE?.contactHours?.contactHoursCzw?.slice(-5)!,
    },
    {
      day: 'Pt',
      from: HOMEPAGE?.contactHours?.contactHoursPt?.slice(0, 5)!,
      to: HOMEPAGE?.contactHours?.contactHoursPt?.slice(-5)!,
    },
  ]

  const GALLERY =
    React.useMemo(
      () =>
        HOMEPAGE?.galleryImg?.map((gallery) => ({
          title: gallery?.galleryImgTitle!,
          slides: gallery?.galleryImgGallery?.map((galleryItem) => ({
            src: galleryItem?.galleryImgGalleryImg?.localFile?.childImageSharp
              ?.gatsbyImageData!,
            alt: galleryItem?.galleryImgGalleryImg?.altText!,
          })),
        })),
      []
    ) ?? []

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Cookiebar />
      <Navigation />
      <Header
        heroImg={
          HOMEPAGE?.bannerHeroImage?.localFile?.childImageSharp
            ?.gatsbyImageData!
        }
        jesionLogoImg={data?.jesionLogo?.childImageSharp?.gatsbyImageData!}
        description={HOMEPAGE?.aboutUsDescription!}
        badgeTitle1={HOMEPAGE?.bannerOrangeMessage!}
        badgeTitle2={HOMEPAGE?.bannerBlueMessage!}
      />
      <About
        img1={
          HOMEPAGE?.aboutUsMap?.localFile?.childImageSharp?.gatsbyImageData!
        }
        img2={
          HOMEPAGE?.aboutUsImg?.localFile?.childImageSharp?.gatsbyImageData!
        }
        badge1Title={HOMEPAGE?.aboutUsYellowMessage!}
        badge2Title={HOMEPAGE?.aboutUsBlueMessage!}
        description={HOMEPAGE?.aboutUsDescription2!}
        benefits={ABOUT_US_BENEFITS}
      />
      <Location
        heading="lokalizacja"
        mainImg={
          HOMEPAGE?.locationgImg?.localFile?.childImageSharp?.gatsbyImageData!
        }
        gallery={LOCATION_GALLERY}
        description={HOMEPAGE?.locationDescription!}
      />
      <Flats
        heading="mieszkania"
        description="Wybierz swoje mieszkanie korzystając z modelu obrotowego poniższej wyszukiwarki mieszkań."
        imgBottom={data?.flatsHeroImg?.childImageSharp?.gatsbyImageData!}
        badgeTitle="Mieszkania <br/> w Twoim Centrum"
      />
      <Contact
        heading="kontakt"
        description={HOMEPAGE?.contactDescription!}
        mapPin={{
          lat: Number(HOMEPAGE?.contactLat!),
          lng: Number(HOMEPAGE?.contactLng!),
        }}
        openingHours={OPENING_HOURS}
      />
      <Gallery heading="galeria" galleries={GALLERY! as GallerySingle[]} />
      <AboutInvestor
        heading="o inwestorze"
        img={
          HOMEPAGE?.aboutInvestorImg?.localFile?.childImageSharp
            ?.gatsbyImageData!
        }
        description={HOMEPAGE?.aboutInvestorDescription!}
      />
      {typeof window !== 'undefined' && (
        <ModalVideo
          src={videoLink}
          modalVisible={modalVisible}
          closeModal={closeModal}
        />
      )}
      <Footer />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPage {
    wpPage(slug: { regex: "/strona-glowna/" }) {
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          sourceUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          sourceUrl
        }
      }
      homepage {
        bannerHeroImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: DOMINANT_COLOR, width: 1920)
            }
          }
        }
        bannerBlueMessage
        bannerOrangeMessage
        aboutUsDescription
        aboutUsDescription2
        aboutUsYellowMessage
        aboutUsBlueMessage
        aboutUsMap {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1360)
            }
          }
        }
        aboutUsImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1360)
            }
          }
        }
        aboutUsAdv {
          aboutUsAdvYellow
          aboutUsAdvOrange2
          aboutUsAdvOrange
          aboutUsAdvBlue
        }
        locationDescription
        locationgImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 810)
            }
          }
        }
        locationImg {
          locationImg1 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 810)
              }
            }
          }
          locationImg2 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 810)
              }
            }
          }
          locationImg3 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 810)
              }
            }
          }
          locationImg4 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 810)
              }
            }
          }
        }
        contactDescription
        contactLat
        contactLng
        contactHours {
          contactHoursPn
          contactHoursWt
          contactHoursSr
          contactHoursCzw
          contactHoursPt
        }
        contactPhoneNumber
        contactEMail
        aboutInvestorDescription
        galleryImg {
          galleryImgTitle
          galleryImgGallery {
            galleryImgGalleryImg {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1360)
                }
              }
            }
          }
        }
        aboutInvestorImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1700, quality: 85)
            }
          }
        }
      }
    }
    flatsHeroImg: file(name: { eq: "flats-hero" }) {
      childImageSharp {
        gatsbyImageData(width: 1360)
      }
    }
    jesionLogo: file(name: { eq: "jesion-logo" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
  }
`
