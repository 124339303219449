import React, { lazy, Suspense } from 'react'
import styled from 'styled-components'
import LazyLoad from 'react-lazyload'
import ScrollAnchor from 'components/shared/ScrollAnchor'

import useBreakpoint from 'hooks/useBreakpoint'

const Contact = lazy(() => import('components/layout/Contact/Contact'))

type OpeningHour = {
  day: string
  from: string
  to: string
}

export type ContactProps = {
  heading: string
  description: string
  mapPin: {
    lat: string | number
    lng: string | number
  }
  openingHours: OpeningHour[]
}

const Section = styled.section`
  position: relative;

  ${({ theme }) => theme.media.lg.min} {
    margin-top: 10rem;
  }
`

const ContactSection: React.FC<ContactProps> = (props) => {
  const { lg } = useBreakpoint()

  return (
    <Section>
      <ScrollAnchor id="contact" />
      <LazyLoad height="100vh" offset={!lg ? 150 : 400} once>
        <Suspense fallback={<></>}>
          <Contact {...props} />
        </Suspense>
      </LazyLoad>
    </Section>
  )
}

export default ContactSection
