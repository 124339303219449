import React, { lazy, Suspense } from 'react'
import styled from 'styled-components'
import LazyLoad from 'react-lazyload'
import ScrollAnchor from 'components/shared/ScrollAnchor'

import { ImageDataLike } from 'gatsby-plugin-image'

import useBreakpoint from 'hooks/useBreakpoint'

const AboutInvestor = lazy(
  () => import('components/layout/AboutInvestor/AboutInvestor')
)

export type AboutInvestorProps = {
  heading: string
  img: ImageDataLike
  description: string
}

const Section = styled.section`
  position: relative;
  margin-bottom: 5rem;

  ${({ theme }) => theme.media.lg.min} {
    margin: 0;
  }
`

const AboutInvestorSection: React.FC<AboutInvestorProps> = (props) => {
  const { lg } = useBreakpoint()
  return (
    <Section>
      <ScrollAnchor id="about-investor" />
      <LazyLoad height="100vh" offset={!lg ? 150 : 400} once>
        <Suspense fallback={<></>}>
          <AboutInvestor {...props} />
        </Suspense>
      </LazyLoad>
    </Section>
  )
}

export default AboutInvestorSection
