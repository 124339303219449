import React, { lazy, Suspense } from 'react'
import styled from 'styled-components'
import LazyLoad from 'react-lazyload'
import ScrollAnchor from 'components/shared/ScrollAnchor'

import useBreakpoint from 'hooks/useBreakpoint'

import type { Image } from 'types/image'

const Gallery = lazy(() => import('components/layout/Gallery/Gallery'))

export type GallerySingle = {
  title: string
  slides: Image[]
}

export type GalleryProps = {
  heading: string
  galleries: GallerySingle[]
}

const Section = styled.section`
  position: relative;
  margin: 8rem 0;

  ${({ theme }) => theme.media.lg.min} {
    margin: 12rem 0 8rem;
  }
`

const GallerySection: React.FC<GalleryProps> = (props) => {
  const { lg } = useBreakpoint()

  return (
    <Section>
      <ScrollAnchor id="gallery" />
      <LazyLoad height="60vh" offset={!lg ? 200 : 500} once>
        <Suspense fallback={<></>}>
          <Gallery {...props} />
        </Suspense>
      </LazyLoad>
    </Section>
  )
}

export default GallerySection
