import React, { lazy, Suspense } from 'react'
import styled from 'styled-components'
import LazyLoad from 'react-lazyload'
import ScrollAnchor from 'components/shared/ScrollAnchor'

import { ImageDataLike } from 'gatsby-plugin-image'

import type { Image } from 'types/image'

import useBreakpoint from 'hooks/useBreakpoint'

const Location = lazy(() => import('components/layout/Location/Location'))

export type LocationProps = {
  heading: string
  mainImg: ImageDataLike
  gallery: Image[]
  description: string
}

const Section = styled.section`
  position: relative;

  ${({ theme }) => theme.media.xl.min} {
    margin-top: 15rem;
  }
`

const LocationSection: React.FC<LocationProps> = (props) => {
  const { lg } = useBreakpoint()
  return (
    <Section>
      <ScrollAnchor id="location" />
      <LazyLoad height="100vh" offset={!lg ? 150 : 500} once>
        <Suspense fallback={<></>}>
          <Location {...props} />
        </Suspense>
      </LazyLoad>
    </Section>
  )
}

export default LocationSection
