import React from 'react'
import styled, { css } from 'styled-components'

import Container from 'components/shared/container'
import Badge from 'components/shared/Badge'
import { Heading, Text } from 'components/shared/typography'

import LazyImage from 'components/shared/lazyImage'

import useBreakpoint from 'hooks/useBreakpoint'

import { ImageDataLike } from 'gatsby-plugin-image'
import { Colors } from 'types/theme'

export type BenefitType = {
  color: string
  text: string
}

type Props = {
  img: ImageDataLike
  badgeTitle: string
  benefits: BenefitType[]
}

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 3rem;

  ${({ theme }) => theme.media.lg.min} {
    margin-bottom: 5rem;
  }
`

const ImgWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 220px;
  border-radius: 20px;

  & > .gatsby-image-wrapper {
    height: 100%;
    overflow: auto;
    border-radius: 20px;
  }

  ${({ theme }) => theme.media.xsm.min} {
    height: unset;
  }

  ${({ theme }) => theme.media.lg.min} {
    & > .gatsby-image-wrapper {
      border-radius: 30px;
    }
  }

  ${({ theme }) => theme.media.xxl.min} {
    & > .gatsby-image-wrapper {
      border-radius: 35px;
    }
  }
`

const BenefitsInfoBox = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 130px;
  padding: 1.5rem 0.5rem 0.75rem 0.75rem;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;

  transition: 350ms ease-in-out;

  ${({ theme }) => theme.media.sm.min} {
    width: 180px;
    padding: 1.5rem 0.75rem 1.5rem 1.5rem;
  }

  ${({ theme }) => theme.media.lg.min} {
    width: 325px;
    right: 3rem;
    max-width: 490px;
    padding: 2.5rem 4rem 1.75rem 5rem;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    border-bottom-right-radius: 0;
  }

  ${({ theme }) => theme.media.xxl.min} {
    right: 3.25rem;
    width: 420px;
    padding: 2.5rem 7.5rem 1.75rem;
  }
`

const Benefit = styled.div<{ color: keyof Colors }>`
  display: flex;
  align-items: center;

  :not(:last-child) {
    margin-bottom: 1rem;
  }

  &:before {
    content: '';
    display: block;
    width: 17px;
    height: 12px;
    margin-right: 1rem;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    ${({ theme, color }) =>
      color &&
      css`
        background-color: ${theme.colors[color]};
      `}
  }

  ${({ theme }) => theme.media.sm.min} {
    ${Text} {
      font-size: 15px;
    }
  }

  ${({ theme }) => theme.media.lg.min} {
    ${Text} {
      font-size: 20px;
    }
    &:before {
      width: 40px;
      height: 25px;
      margin-right: 1.75rem;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    :not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  ${({ theme }) => theme.media.xxl.min} {
    ${Text} {
      font-size: 30px;
    }
  }
`

const InfoBoxBadge = styled(Badge)`
  bottom: 0;
  left: 0;
`

const InfoBoxBadgeWhite = styled(InfoBoxBadge)`
  bottom: 0;
  left: 0;
  height: 7px;
  width: 30%;
  border-bottom-right-radius: 5px;

  ${({ theme }) => theme.media.lg.min} {
    height: 12px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    height: 16px;
  }
`
const InfoBoxBadgeOrange = styled(InfoBoxBadge)`
  bottom: 0;
  left: 0;
  height: 12px;
  width: 28%;
  transform: translateX(-62.5%);

  ${({ theme }) => theme.media.lg.min} {
    height: 25px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    height: 40px;
  }
`
const InfoBoxBadgeSecondary = styled(InfoBoxBadge)`
  bottom: 0;
  left: 0;
  height: 12px;
  width: 45%;
  transform: translateX(-62.5%) translateY(12px);

  ${({ theme }) => theme.media.lg.min} {
    height: 25px;
    transform: translateX(-62.5%) translateY(25px);
  }

  ${({ theme }) => theme.media.xxl.min} {
    height: 40px;
    transform: translateX(-62.5%) translateY(40px);
  }
`
const InfoBoxBadgeYellow = styled(InfoBoxBadge)`
  bottom: 0;
  left: 0;
  height: 5px;
  width: 33%;
  transform: translateX(-66.5%) translateY(19px);

  ${({ theme }) => theme.media.lg.min} {
    transform: translateX(-66.5%) translateY(33px);
    height: 7px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    height: 10px;
    transform: translateX(-66.5%) translateY(55px);
  }
`

const StyledHeading = styled(Heading)`
  letter-spacing: 0.2px;

  ${({ theme }) => theme.media.sm.min} {
    font-size: 16px;
  }

  ${({ theme }) => theme.media.lg.min} {
    font-size: 20px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    font-size: 25px;
  }
`

const BadgeDark = styled(Badge)`
  bottom: 0;
  left: 0;
  width: 60%;
  max-width: 300px;
  height: 35px;
  padding-left: 1rem;
  transition: 350ms ease-in-out;

  ${({ theme }) => theme.media.sm.min} {
    height: 45px;
    padding-left: 2rem;
  }

  ${({ theme }) => theme.media.lg.min} {
    width: 42%;
    max-width: 400px;
    height: 60px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    max-width: 550px;
    width: 50%;
    height: 75px;
    padding-left: 7rem;
  }
`

const BadgeWhite = styled(Badge)`
  top: 0;
  right: 1.5rem;
  width: 40%;
  height: 10px;
  transform: translateY(-60%);
  transition: 350ms ease-in-out;

  ${({ theme }) => theme.media.lg.min} {
    height: 15px;
    right: 2.5rem;
  }

  ${({ theme }) => theme.media.xxl.min} {
    height: 26px;
  }
`

const About2: React.FC<Props> = ({ img, badgeTitle, benefits }) => {
  const { s } = useBreakpoint()

  return (
    <Wrapper>
      <Container>
        <ImgWrapper>
          <LazyImage src={img} alt="map" />
          <BadgeDark color="dark">
            <StyledHeading
              as="h3"
              size={18}
              margin="0"
              themecolor="white"
              dangerouslySetInnerHTML={{ __html: badgeTitle }}
            />
            <BadgeWhite color="white" />
          </BadgeDark>
          <BenefitsInfoBox>
            {benefits.map(({ color, text }) => (
              <Benefit key={text} color={color as keyof Colors}>
                <Text
                  size={12}
                  themecolor="white"
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </Benefit>
            ))}
            {s && (
              <>
                <InfoBoxBadgeWhite color="white" />
                <InfoBoxBadgeOrange color="orange" />
                <InfoBoxBadgeSecondary color="secondary" />
                <InfoBoxBadgeYellow color="yellow" />
              </>
            )}
          </BenefitsInfoBox>
        </ImgWrapper>
      </Container>
    </Wrapper>
  )
}

export default About2
