import styled, { css } from 'styled-components'
import type { Colors } from 'types/theme'

const Badge = styled.div<{ color: keyof Colors }>`
  display: flex;
  align-items: center;
  position: absolute;
  position: absolute;
  border-radius: 80px;
  ${({ theme, color }) =>
    color &&
    css`
      background-color: ${theme.colors[color]};
    `}
`
export default Badge
