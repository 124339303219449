import React, { lazy, Suspense } from 'react'
import styled from 'styled-components'
import LazyLoad from 'react-lazyload'
import ScrollAnchor from 'components/shared/ScrollAnchor'

import { ImageDataLike } from 'gatsby-plugin-image'

import useBreakpoint from 'hooks/useBreakpoint'

const Flats = lazy(() => import('components/layout/Flats/Flats'))

export type FlatsProps = {
  heading: string
  description: string
  imgBottom: ImageDataLike
  badgeTitle: string
}

const Section = styled.section`
  position: relative;

  ${({ theme }) => theme.media.xl.min} {
    margin-top: 15rem;
  }
`

const FlatsSection: React.FC<FlatsProps> = (props) => {
  const { lg } = useBreakpoint()

  return (
    <Section>
      <ScrollAnchor id="flats" />
      <LazyLoad height="100vh" offset={!lg ? 150 : 300} once>
        <Suspense fallback={<></>}>
          <Flats {...props} />
        </Suspense>
      </LazyLoad>
    </Section>
  )
}

export default FlatsSection
