import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import Badge from 'components/shared/Badge'
import LazyImage from 'components/shared/lazyImage'

import { ImageDataLike } from 'gatsby-plugin-image'
import { Heading } from 'components/shared/typography'

import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  heroImg: ImageDataLike
  jesionLogoImg: ImageDataLike
  description: string
  badgeTitle1: string
  badgeTitle2: string
}

const HeaderWrapper = styled.header`
  position: relative;
  width: 100%;
  margin-top: ${({ theme }) => theme.navSize.mobile};
`

const ImgWrapper = styled.div`
  position: relative;
  height: 320px;
  min-height: 320px;
  transition: 150ms ease-in-out;

  & > .gatsby-image-wrapper {
    height: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  ${({ theme }) => theme.media.sm.min} {
    height: auto;
    min-height: unset;
  }

  ${({ theme }) => theme.media.lg.min} {
    margin-top: ${({ theme }) => theme.navSize.desktop};
    & > .gatsby-image-wrapper {
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
    }
  }

  ${({ theme }) => theme.media.xl.min} {
    height: 780px;

    & > .gatsby-image-wrapper {
      img {
        object-position: 50% 100%;
      }
    }
  }

  ${({ theme }) => theme.media.xxl.min} {
    & > .gatsby-image-wrapper {
      border-bottom-left-radius: 35px;
      border-bottom-right-radius: 35px;
    }
  }
`

const Description = styled(Heading)`
  position: relative;
  width: 100%;
  max-width: ${({ theme }) =>
    `calc(${theme.container.widthMobile} + (${theme.container.padding} * 2))`};
  margin: 4rem auto;
  padding: 1.5rem 0 1.5rem 3rem;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: calc(200% - 1.5rem);
    height: 100%;
    border: 1px solid ${({ theme }) => theme.colors.dark};
    border-top-left-radius: 90px;
    border-bottom-left-radius: 90px;
    pointer-events: none;
  }

  br {
    display: none;
  }

  ${({ theme }) => theme.media.lg.min} {
    max-width: ${({ theme }) =>
      `calc(${theme.container.width} + (${theme.container.padding} * 2))`};
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    padding-left: ${({ theme }) => theme.container.padding};
    padding-right: ${({ theme }) => theme.container.padding};

    &:after {
      width: calc(200% - 4rem);
    }
  }

  ${({ theme }) => theme.media.xxl.min} {
    br {
      display: inline-block;
    }

    margin-top: 8.5rem;
    margin-bottom: 9.5rem;
  }

  ${({ theme }) => theme.media.xxxl.min} {
    max-width: ${({ theme }) =>
      `calc(${theme.container.widthXXXL} + (${theme.container.padding} * 2))`};
  }
`

const StyledHeading = styled(Heading)`
  letter-spacing: 0.7px;
  font-family: 'FaktProSemiBold';
`

const BadgeOrange = styled(Badge)`
  display: flex;
  align-items: flex-start;
  top: 25%;
  left: 15%;
  transform: translateX(-25%) translateY(-50%);
  height: 60px;
  width: max-content;
  padding: 0.85rem 1.5rem;
  transition: 350ms ease-in-out;

  ${({ theme }) => theme.media.lg.min} {
    top: 22%;
    left: 12%;
    height: 70px;
    padding: 1rem 2rem;
  }

  ${({ theme }) => theme.media.xxl.min} {
    height: 95px;
    padding: 1.5rem 2.5rem;
  }
`

const BadgeDark = styled(Badge)`
  top: 25%;
  left: 15%;
  width: max-content;
  height: 70px;
  padding: 0 4rem 0 3rem;
  transition: 350ms ease-in-out;

  ${({ theme }) => theme.media.lg.min} {
    top: 22%;
    left: 12%;
    height: 80px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    height: 105px;
    padding: 0 5rem 0 4rem;
  }
`

const BadgeYellow = styled(Badge)`
  bottom: 0;
  left: 2.5rem;
  transform: translateY(50%);
  height: 18px;
  width: 27.5%;
  transition: 350ms ease-in-out;

  ${({ theme }) => theme.media.lg.min} {
    height: 22px;
    width: 30%;
  }

  ${({ theme }) => theme.media.xxl.min} {
    height: 28px;
    width: 33%;
  }
`

const JesionLogo = styled.div`
  position: absolute;
  top: 15px;
  right: 10px;

  width: 120px;

  & > .gatsby-image-wrapper {
    width: 100%;
    img {
      width: 100%;
    }
  }

  ${({ theme }) => theme.media.sm.min} {
    top: 25%;
    right: 15%;
    transform: translateY(-50%);
  }

  ${({ theme }) => theme.media.md.min} {
    width: 20%;
    max-width: 275px;
  }

  ${({ theme }) => theme.media.lg.min} {
    right: 12%;
    transform: translateX(25%) translateY(-50%);
  }
`

const Header: React.FC<Props> = ({
  heroImg,
  jesionLogoImg,
  description,
  badgeTitle1,
  badgeTitle2,
}) => {
  const { lg, xxl } = useBreakpoint()

  return (
    <HeaderWrapper>
      <Container>
        <ImgWrapper>
          <LazyImage src={heroImg} alt="hero-investment" />
          <BadgeOrange color="orange">
            <StyledHeading
              as="h3"
              size={xxl ? 24 : 22}
              themecolor="white"
              transform="uppercase"
              margin="0"
              dangerouslySetInnerHTML={{ __html: badgeTitle1 }}
            />
          </BadgeOrange>
          <BadgeDark color="dark">
            <StyledHeading
              as="h3"
              size={xxl ? 24 : 22}
              themecolor="white"
              margin="0"
              dangerouslySetInnerHTML={{ __html: badgeTitle2 }}
            />
            <BadgeYellow color="yellow" />
          </BadgeDark>
          <JesionLogo>
            <LazyImage src={jesionLogoImg} alt="jesion-logo" />
          </JesionLogo>
        </ImgWrapper>
        <Description
          size={lg ? 22 : 18}
          sizeDiff={0.3}
          themecolor="dark"
          align="center"
          line={1.45}
          margin="0"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Container>
    </HeaderWrapper>
  )
}

export default Header
