import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import Badge from 'components/shared/Badge'
import { Heading, Text } from 'components/shared/typography'

import { ImageDataLike } from 'gatsby-plugin-image'
import LazyImage from 'components/shared/lazyImage'
import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  img: ImageDataLike
  badgeTitle: string
  description: string
}

const Wrapper = styled.div`
  width: 100%;
`

const ImgWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 240px;
  border-radius: 20px;
  overflow: overlay;

  & > .gatsby-image-wrapper {
    height: 100%;
  }

  ${({ theme }) => theme.media.xsm.min} {
    height: unset;
  }

  ${({ theme }) => theme.media.lg.min} {
    border-radius: 30px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    border-radius: 35px;
  }
`

const HeadingBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transform: translateX(-51.5rem);
  width: ${({ theme }) =>
    `calc(75% + 50rem + (${theme.container.paddingMobile}))`};
  margin: 4rem 0;
  padding: 1.75rem 5rem 1.75rem 0;
  border: 1px solid ${({ theme }) => theme.colors.dark};
  border-top-right-radius: 90px;
  border-bottom-right-radius: 90px;
  border-left: none;
  transition: 150ms ease-in-out;

  ${({ theme }) => theme.media.lg.min} {
    width: ${({ theme }) =>
      `calc(50% + 50rem + (${theme.container.paddingMobile}))`};
    padding: 1.5rem 5rem 1.5rem 0;
    height: 90px;
    border-width: 2px;

    ${Text} {
      max-width: 47%;
    }
  }
`

const Description = styled.div`
  width: 100%;
  margin: 4rem 0;
  padding: 0 0 2.5rem 0;

  ${({ theme }) => theme.media.lg.min} {
    margin: 4rem 0;
  }
`

const StyledHeading = styled(Heading)`
  font-family: 'FaktProSemiBold';
  letter-spacing: 0.7px;
`

const BadgeYellow = styled(Badge)`
  top: 0;
  left: 0;
  width: 60%;
  max-width: 300px;
  height: 38px;
  padding-left: 2rem;
  transition: 350ms ease-in-out;

  ${({ theme }) => theme.media.lg.min} {
    width: 40%;
    max-width: 480px;
    height: 52px;
    padding-left: 3.25rem;
  }
`

const BadgeWhite = styled(Badge)`
  bottom: 0;
  left: 1.5rem;
  width: 52%;
  max-width: 105px;
  height: 12px;
  transform: translateY(60%);
  transition: 350ms ease-in-out;

  ${({ theme }) => theme.media.lg.min} {
    max-width: 160px;
    height: 17px;
    left: 3rem;
  }
`

const About1: React.FC<Props> = ({ img, badgeTitle, description }) => {
  const { lg } = useBreakpoint()

  return (
    <Wrapper>
      <Container>
        <ImgWrapper>
          <LazyImage src={img} alt="map" />
          <BadgeYellow color="yellow">
            <StyledHeading
              as="h3"
              size={lg ? 22 : 18}
              margin="0"
              dangerouslySetInnerHTML={{ __html: badgeTitle }}
            />
            <BadgeWhite color="white" />
          </BadgeYellow>
        </ImgWrapper>
        <HeadingBadge>
          <Heading
            as="h2"
            size={25}
            sizeDiff={0.3}
            themecolor="dark"
            align="center"
            transform="uppercase"
            margin="0"
            dangerouslySetInnerHTML={{ __html: 'O inwestycji' }}
          />
        </HeadingBadge>
        <Description>
          <Text
            size={lg ? 18 : 15}
            themecolor="dark"
            dangerouslySetInnerHTML={{ __html: description }}
            line={lg ? 1.55 : 1.4}
          />
        </Description>
      </Container>
    </Wrapper>
  )
}

export default About1
