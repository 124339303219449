import React, { lazy, Suspense } from 'react'
import styled from 'styled-components'
import LazyLoad from 'react-lazyload'
import ScrollAnchor from 'components/shared/ScrollAnchor'

import useBreakpoint from 'hooks/useBreakpoint'

const Footer = lazy(() => import('components/layout/Footer/Footer'))

export type FooterProps = {}

const Section = styled.section`
  position: relative;
`

const FooterSection: React.FC<FooterProps> = (props) => {
  const { lg } = useBreakpoint()

  return (
    <Section>
      <ScrollAnchor id="footer" />
      <LazyLoad height="20vh" offset={!lg ? 150 : 300} once>
        <Suspense fallback={<></>}>
          <Footer {...props} />
        </Suspense>
      </LazyLoad>
    </Section>
  )
}

export default FooterSection
