import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Cookie from 'js-cookie'

const CookiesWrapper = styled.aside`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  display: block;
  background: ${({ theme }) => theme.colors.gray};
  transition: 0.3s;
  opacity: 1;
  ${({ hidden }) =>
    hidden &&
    css`
      opacity: 0;
      transform: translateY(100px);
    `}
`

const CookiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px 30px 15px;
  height: 100%;
  ${({ theme }) => theme.media.md.min} {
    flex-direction: row;
    padding: 15px 30px;
  }
`

const CookiesButton = styled.button`
  margin: 5px 0;
  width: 180px;
  min-width: 180px;
  height: 47px;
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray};
  border-radius: 25px;
  background-color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  letter-spacing: 1px;
`

const CookiesText = styled.p`
  margin: 0;
  font-size: 16px;
  text-align: justify;
  padding-bottom: 10px;
  ${({ theme }) => theme.media.md.min} {
    text-align: left;
    padding-right: 15px;
    padding-bottom: 0;
  }
`

const Cookies = () => {
  const [visible, setVisible] = useState(false)
  const [removeAlert, setRemoveAlert] = useState(false)

  const acceptCookies = () => {
    Cookie.set('nawrot-cookies', 'true')
    setVisible(false)
    setTimeout(() => setRemoveAlert(true), 500)
    if (localStorage) {
      localStorage.setItem('nawrot-cookies', 'true')
    }
  }

  useEffect(() => {
    if (localStorage) {
      const value = localStorage.getItem('nawrot-cookies')
      if (value && Boolean(value) === true) {
        setVisible(false)
        setTimeout(() => setRemoveAlert(true), 500)
      } else {
        setVisible(true)
      }
    } else {
      setVisible(true)
    }
  }, [])

  return (
    <>
      {!removeAlert && (
        <CookiesWrapper hidden={!visible}>
          <CookiesContainer>
            <CookiesText>
              Strona korzysta z plików cookies w celu realizacji usług. Możesz
              określić warunki przechowywania lub dostępu do plików cookies w
              Twojej przeglądarce.
            </CookiesText>
            <CookiesButton onClick={acceptCookies}>Rozumiem</CookiesButton>
          </CookiesContainer>
        </CookiesWrapper>
      )}
    </>
  )
}

export default Cookies
