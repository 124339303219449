import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import Icon from 'components/shared/icon'
import closeIcon from 'assets/icons/close.svg'
import useBreakpoint from 'hooks/useBreakpoint'
import useOutsideClick from 'hooks/useOutsideClick'
import ReactPlayer from 'react-player'

type Props = {
  src: string
  modalVisible: boolean
  closeModal: () => void
}

const Overlay = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  background: rgba(0, 0, 0, 0.725);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  z-index: 999;

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`
const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 1000px;

  @media (min-width: 1024px) {
    width: 80%;
    max-width: 1440px;
  }
`
const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;
  transition: 200ms ease-in-out;
`
const ModalVideo: React.FC<Props> = ({ src, modalVisible, closeModal }) => {
  useEffect(() => {
    const handleKeyboardNavigation = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeModal()
      }
    }
    window.addEventListener('keydown', handleKeyboardNavigation)
    return () => window.removeEventListener('keydown', handleKeyboardNavigation)
  }, [])

  const { xl } = useBreakpoint()

  const videoWrapperRef = useRef(null)

  useOutsideClick({ ref: videoWrapperRef, handler: closeModal })

  return ReactDOM.createPortal(
    <Overlay isVisible={modalVisible}>
      <InnerWrapper ref={videoWrapperRef}>
        <ReactPlayer
          url={src}
          width="100%"
          height="100%"
          playing={modalVisible}
          controls
          muted
        />
      </InnerWrapper>
      <CloseButton type="button" onClick={closeModal} aria-label="close">
        <Icon src={closeIcon} alt="close" size={xl ? 30 : 20} />
      </CloseButton>
    </Overlay>,
    document.body
  )
}
export default ModalVideo
