import React, { lazy, Suspense } from 'react'
import styled from 'styled-components'
import LazyLoad from 'react-lazyload'
import ScrollAnchor from 'components/shared/ScrollAnchor'

import { ImageDataLike } from 'gatsby-plugin-image'

import About1 from 'components/layout/About/About1'
import About2 from 'components/layout/About/About2'

import type { BenefitType } from 'components/layout/About/About2'

// const Location = lazy(() => import('components/Location/Location'))

type Props = {
  img1: ImageDataLike
  badge1Title: string
  img2: ImageDataLike
  badge2Title: string
  description: string
  benefits: BenefitType[]
}

const Section = styled.section`
  position: relative;
`

const AboutSection: React.FC<Props> = ({
  img1,
  img2,
  badge1Title,
  badge2Title,
  description,
  benefits,
}) => {
  return (
    <Section>
      <ScrollAnchor id="about" />
      {/* <LazyLoad height="100vh" offset={500} once> */}
      {/* <Suspense fallback={<></>}> */}
      <About1 img={img1} badgeTitle={badge1Title} description={description} />
      <About2 img={img2} badgeTitle={badge2Title} benefits={benefits} />
      {/* </Suspense> */}
      {/* </LazyLoad> */}
    </Section>
  )
}

export default AboutSection
